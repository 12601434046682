import http from '@/utils/request.js'


// 检查是否可以进入课件详情
export const wareCheckPlay = (params) => {
  return http.post('/w/course/class/ware/check/play', params, { domainName: 'api' })
}

//课程列表
export const courseList = (params) => {
  return http.post('/n/course/class/page', params, { domainName: 'api' })
}
//课程详情
export const courseInfo = (params) => {
  return http.post('/n/course/class/info', params, { domainName: 'api' })
}
//报名课程
export const courseSign = (params) => {
  return http.post('/w/course/sign/sign', params, { domainName: 'api' })
}
//课件组
export const groupList = (params) => {
  return http.post('/n/course/class/ware/group/list', params, { domainName: 'api' })
}
//分组查询
export const wareInfo = (params) => {
  return http.post('/n/course/class/course/ware/list', params, { domainName: 'api' })
}
//加入课件
export const wareJoin = (params) => {
  return http.post('/w/course/ware/rate/join', params, { domainName: 'api' })
}
// 个人中心
export const userInfo = (params) => {
  return http.post('/w/course/personal/center/info', params, { domainName: 'api' })
}
//我的课程 -   课程列表
export const ratePage = (params) => {
  return http.post('/w/course/class/my/rate/page', params, { domainName: 'api' })
}
//我的课程 -   课件列表
export const rateWarePage = (params) => {
  return http.post('/w/course/class/my/rate/ware/page', params, { domainName: 'api' })
}
//标签
export const labelTree = (params) => {
  return http.post('/n/course/class/label/tree', params, { domainName: 'api' })
}