<template>
  <div class="bg">
    <div class="filtrate">
      <div class="wrapper">
        <div class="filtrate-item" v-for="(item, index) in labelList" :key="index">
          <div class="label" style="margin:0 20px">{{item.name}}:</div>
          <div style="margin:0 20px" :class="{'active': groupingIndex == -1}" @click.stop="handle()">全部</div>
          <div class="classify">
            <div
              v-for="(items, i) in item.children" :key="i"
              class="classify-btn"
              :class="{'active': groupingIndex == i}"
              @click.stop="handleTrigger(item.groupingValue, items.labelValue, i)"
            >{{items.name}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper">
      <!-- <div class="classify" style="margin: 30px 0 10px;">
        <div class="classify-btn" @click="handleTrigger(item, index, 'activeC')" :class="activeC == index? 'active' : '' " v-for="(item, index) in classifyListC" :key="index">{{item}}</div>
      </div> -->
      <div v-if="courseList && courseList.length > 0" style="margin-top:30px;">
        <div class="search">
          <Course :list="courseList" />
        </div>
        <div style="margin: 30px 0; display: flex; justify-content: flex-end;">
          <a-pagination
          :default-current="current"
          :page-item-style="{ 'border': '1px solid rgba(0,0,0,0.1500)', 'border-radius': '2px' }"
          :active-page-item-style="{ 'background-color': 'transparent', 'border': '1px solid #4F17A8','color': '#4F17A8'}"
          :total="total"
          :default-page-size="size"
          @change="change" />
        </div>
      </div>
      <div v-else ><a-empty /></div>
    </div>
  </div>
</template>

<script setup>
  import { ref,reactive, toRefs } from 'vue'
  import Course from '@/components/course/course.vue'
  import * as courseApi from '@/api/course';
  
  //课程列表
  const courseList = ref([])
  const current = ref(1)
  const size = ref(20)
  const total = ref(3)
  const getList = (current, size, groupValue, labelValue ) => {
    courseApi.courseList({current,size,groupValue,labelValue}).then(res => {
      if(res.code == 20000){
        courseList.value = res.result.records;
        current = res.result.current;
        total.value = res.result.total;
      }
    })
  };
  getList(current.value, size.value, '', '')
  //标签
  const labelList = ref([]);
  const groupingIndex = ref(-1);
  const getLabel = () => {
    let params = {
      isShow:0
    }
    courseApi.labelTree(params).then(res => {
      labelList.value = res.result;
    })
  };
  getLabel();
  // const classifyListC = reactive([
  //   '默认', '销量', '最新', '收藏', '价格'
  // ])
  const handle = () => {
    groupingIndex.value = -1;
    getList(1, 20, '', '')
  }
  const handleTrigger = (id,val,i) => {
    groupingIndex.value = i;
    getList(1, 20, id, val)
  }
  const change = (val) => {
    getList(val, 20, '', ''); 
  }
</script>

<style lang="less" scoped>
.active {
  color: #4F17A8;
  font-weight: 700;
}
.bg {
  background: #F7F6FA;
  padding-bottom: 20px;
  .filtrate {
    background: #fff;
    padding: 20px 0 10px;
    .filtrate-item {
      display: flex;
      font-size: 14px;
      margin-top: 10px;
      color: #333;
      cursor: pointer;
      .label {
        width: auto;
        color: #344058;
      }
    }
  }
  .classify {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    .classify-btn {
      cursor: pointer;
      margin-right: 30px;
      margin-bottom: 20px;
    }
  }
}
</style>
