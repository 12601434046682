<template>
  <div class="course">
    <div class="course-container">
      <div class="course-item cursor" v-for="(item, index) in list" :key="index" @click="next(item.id)">
        <div class="img">
          <img style="width: 100%; height: 160px; border-radius: 4px 4px 0 0;" :src="`/_fid/` + item.cover" alt="">
        </div>
        <div class="course-item-c">
          <div class="floor-t">{{item.name}}</div>
          <div class="floor-b">
            <div v-if="item.studyNumber" class="floor-b-left">{{ item.studyNumber }} 人已学习</div>
            <!-- <div class="floor-b-right">加入学习</div> -->
          </div>
        </div>
        <img v-if="item.isHot == 1" src="../../assets/images/hot.png" alt="" class="hot-img">
        <div v-if="item.isLearnable == 1" class="zhong">学习中</div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'

export default {
  props: {
    list: { type: Array }
  },
  setup() {
    const router = useRouter()
    const state = reactive({ })
    const next = (id) => {
      router.push({
        path: '/course/list',
        query:{
          id:id
        }
      })
    }
    return {
      ...toRefs(state),
      next
    }
  }
}
</script>

<style lang="less" scoped>
.course-container {
  display: flex;
  flex-wrap: wrap;
  .course-item:not(:nth-child(4n)) {
    margin-right: calc(4% / 3);
  }
  .course-item {
    margin-bottom: calc(4% / 3);
    width: 24%;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.1000);
    border-radius: 4px;
    position: relative;
    .course-item-c {
      padding: 8px 10px 15px;
      .floor-t {
        font-size: 16px;
        color: #333;
        height: 50px;
        line-height: 25px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .floor-b {
        margin-top: 10px;
        display: flex;
        align-items: center;
        .floor-b-left {
          flex: 1;
          color: #7B7B7B;
          font-size: 12px;
        }
        .floor-b-right {
          width: 72px;
          height: 24px;
          background: #207AFF;
          border-radius: 2px;
          color: #FFFFFF;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .hot-img {
      height: 50px;
      width: 50px;
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .zhong {
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      padding: 0 12px;
      border-radius: 0 20px 20px 0;
      background-color: rgb(255, 118, 26, .5);
      color: #FFFFFF;
      position: absolute;
      left: 0;
      top: 14px;
    }
  }
  .course-item:hover {
    box-shadow: 1px 1px 15px 5px rgba(0,0,0,0.2);
  }
}
</style>
